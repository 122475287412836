<template>
  <b-overlay
    :show="is_loading"
    blur="50"
    variant="white"
    spinner-variant="primary"
    spinner-lg
    rounded="lg"
  >
    <transition name="zoom-fade" mode="out-in">
      <div v-if="confirm">
        <div class="d-flex">
          <Lottie :options="animationData" :height="100" :width="200" />
        </div>
				 <b-row>
						<b-col md="12" lg="12">
								<h3 class="title dark animate__animated " data-anime="animate__fadeInUp" data-delay="150" style="text-align: center">
										Заявка успешно отправлена! 
								</h3>
								<p class="dark animate__animated text-overlay" data-anime="animate__fadeIn" data-delay="200" style="text-align: center; margin: 15px 30px 30px 30px">
									В ближайшее время с вами свяжется менеджер
								</p>
						</b-col>
				</b-row>
        
      </div>
      <div v-else>
        <div>
          <b-card class="mb-0">
            <!-- form -->
            <validation-observer ref="callbackForm">
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="validationForm"
              >
                <!-- username -->
                <b-form-group label="Ваше имя" label-for="username">
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    rules="required"
                  >
                    <b-form-input
                      id="username"
                      v-model="username"
                      :state="errors.length > 0 ? false : null"
                      name="register-username"
                      placeholder="johndoe"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- messangers -->
                <b-form-group
                  label="Как с Вами удобнее связаться?"
                  label-for="type"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Phone"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <!-- <b-input-group-prepend is-text>
                            <b-link v-if="country" @click="showCountryModal = true" class="text-black" style="font-size: 13px; display: flex; align-items: center;">
                                <b-img :src="country.flag" fluid style="width: 15px; height: 15px; margin-right: 6px;" />
                                <span>{{ country.code }}</span>
                            </b-link>
                      </b-input-group-prepend>    -->
                    <div class="mesanger-block">
                      <b-img
                        v-if="selectedMessanger.call"
                        src="~@/assets/images/icons/messangers/call-q.jpg"
                        rounded="circle"
                        fluid
                        alt="telegram"
                        @click="selectMessanger('call', false)"
                      ></b-img>
                      <b-img
                        v-else
                        src="~@/assets/images/icons/messangers/call-dis.jpg"
                        rounded="circle"
                        fluid
                        alt="telegram"
                        @click="selectMessanger('call', false)"
                      ></b-img>
                      <span class="text-muted" style="font-size: 9px">звонок</span>
                    </div>
                      
                    <div class="mesanger-block">
                      <b-img
                        v-if="selectedMessanger.whatsapp"
                        src="~@/assets/images/icons/messangers/whatsapp.png"
                        rounded="circle"
                        fluid
                        alt="whatsapp"
                        @click.prevent="selectMessanger('whatsapp', false)"
                      ></b-img>
                      <b-img
                        v-else
                        src="~@/assets/images/icons/messangers/whatsapp-dis.png"
                        rounded="circle"
                        fluid
                        alt="whatsapp"
                        @click.prevent="selectMessanger('whatsapp', true)"
                      ></b-img>
                      <span class="text-muted" style="font-size: 9px">whatsapp</span>
                    </div>

                    <div class="mesanger-block">
                      <b-img
                        v-if="selectedMessanger.viber"
                        src="~@/assets/images/icons/messangers/viber.png"
                        rounded="circle"
                        fluid
                        alt="viber"
                        @click="selectMessanger('viber', false)"
                      ></b-img>
                      <b-img
                        v-else
                        src="~@/assets/images/icons/messangers/viber-dis.png"
                        rounded="circle"
                        fluid
                        alt="viber"
                        @click="selectMessanger('viber', true)"
                      ></b-img>
                      <span class="text-muted" style="font-size: 9px">viber</span>
                    </div>

                    <div class="mesanger-block">
                      <b-img
                        v-if="selectedMessanger.telegram"
                        src="~@/assets/images/icons/messangers/telegram.png"
                        rounded="circle"
                        fluid
                        alt="telegram"
                        @click="selectMessanger('telegram', false)"
                      ></b-img>
                      <b-img
                        v-else
                        src="~@/assets/images/icons/messangers/telegram-dis.png"
                        rounded="circle"
                        fluid
                        alt="telegram"
                        @click="selectMessanger('telegram', true)"
                      ></b-img>
                      <span class="text-muted" style="font-size: 9px">telegram</span>
                    </div>

                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- phone number -->
                <b-form-group
                  v-show="!isCodeSend"
                  label="Номер телефона"
                  label-for="phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Phone"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-input-group-prepend is-text :class="country ? 'cb-flags' : 'hidden'">
                            <b-link v-if="country" @click="showCountryModal = true" class="text-black r-border0" style="font-size: 13px; display: flex; align-items: center;">
                                <country-flag :country="country.iso" size='small'/>
                                <span>{{ country.code }}</span>
                            </b-link>
                      </b-input-group-prepend>   
                      <b-form-input
                        id="phone"
                        v-model="regPhone"
                        :state="errors.length > 0 ? false : null"
                        name="register-phone"
                        :placeholder="
                          country ? country.mask : '+7 (###) ###-##-##'"
                        v-mask="country ? country.mask : '+7 (###) ###-##-##'"
                        class="form-control-merge"
                        :class="country ? `l-border0` : `l-border100 p-f` "
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit button -->
                <b-button variant="callback" block type="submit">
                  Получить консультацию
                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text
              v-if="error"
              variant="danger"
              class="text-danger text-center mt-2"
            >
              {{ error }}</b-card-text
            >
          </b-card>
        </div>

        <b-modal
          v-model="showCountryModal"
          centered
          title="Выбрать страну"
          ok-only
          ok-title="Готово"
          modal-class="with-no-paddings"
        >
          <b-list-group flush style="max-height: 255px; overflow-y: scroll;">
            <b-list-group-item
              v-for="c in countries"
              :key="c.iso"
              @click="
                country = c;
                showCountryModal = false;
              "
              :active="country ? c.iso === country.iso : false"
              style="cursor: pointer;"
            >
              <div class="d-flex justify-content-start align-items-center">
                <div style="margin-right: 8px;">
                  <b-img :src="c.flag" fluid />
                </div>
                <div class="flex-grow-1">
                  {{ c.name }}
                </div>
                <div>
                  {{ c.code }}
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-modal>
      </div>
    </transition>

         
    <b-modal 
        v-model="showCountryModal"
        centered
        title="Выбрать страну"
        ok-only
        ok-title="Готово"
        modal-class="with-no-paddings"
    >

        <b-list-group flush style="max-height: 255px; overflow-y: scroll;">
            <b-list-group-item v-for="c in countries" :key="c.iso" @click="country = c; showCountryModal = false;" :active=" country ? (c.iso === country.iso) : false " style="cursor: pointer;">
                <div class="d-flex justify-content-start align-items-center">
                    <div style="margin-right: 8px;">
                        <!-- <b-img :src="c.flag" fluid /> -->
                        <country-flag :country="c.iso" size='small'/>
                    </div>
                    <div class="flex-grow-1">
                        {{ c.name }}
                    </div>
                    <div>
                        {{ c.code }}
                    </div>
                </div>
            </b-list-group-item>
        </b-list-group>
      
    </b-modal> 

  </b-overlay>
</template>

<script>
import User from "@/modules/user/";
// import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email, password, confirmed, checked } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import * as SuccessAnimeView from "@/assets/anime/success.json"

import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";

import useAppConfig from "@core/app-config/useAppConfig";

export default {
  props: {
    selected: {
      type: String,
      default: 'call'
    }
  },
  data() {
    return {
      User,
      country: null,
      countries: require("countries-phone-masks"),
      showCountryModal: false,

      selectedMessanger: {
        whatsapp: false,
        telegram: false,
        viber: false,
        call: true,
      },

      regPhone: "",
      username: "",

      i: null,

      status: "",

      error: null,

      isCodeSend: false,

      skin: useAppConfig().skin,

			animationData: { 
				animationData: SuccessAnimeView.default,
				loop: false
			}, 
			confirm: null,
			is_loading: false,

      // validation rules
      required,
      confirmed,
      password,
      email,
      checked,
    };
  },
  methods: {
    selectMessanger(messanger, event) {
      this.selectedMessanger = {
        whatsapp: false,
        telegram: false,
        viber: false,
        call: false,
      };

      if (!event) {
        this.selectedMessanger.call = true;
      } else this.selectedMessanger[messanger] = event;

    },

    closeModal() {
      this.$root.$emit("closeModal");
    },

    validationForm() {
      this.error = null;

      this.$refs.callbackForm.validate().then((success) => {
        if (success) {
          let messanger = "";
          for (let key in this.selectedMessanger) {
            if (this.selectedMessanger[key] === true) messanger = key;
          }


          this.$request
            .post("integrations/amocrm.createLead", {
              name: this.username,
              phone: this.country ? `${this.country.code}${this.regPhone}` : this.regPhone,
              messanger: messanger == 'call' ? 'звонок' : messanger,
              utm_tags: localStorage.getItem("utmTags") ? JSON.parse(localStorage.getItem("utmTags")) : null
            })
            .then((result) => {
              
							this.confirm = true;
              // localStorage.removeItem('utmTags');
							setTimeout(() => {
								this.$router.push("register");
           		}, 2500 );
            })
            .catch((err) => {
              this.error = err.message;
            });
          // this.closeModal();
        }
      });
    },
  },
  computed: {},
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  watch: {},
  mounted() {
    this.selectMessanger(this.selected, true)

    // const userLocale = Intl.DateTimeFormat().resolvedOptions().locale.toUpperCase();
    // this.country = this.countries.find( country => country.iso === userLocale );
    // if (typeof this.country !== 'object' && !this.country.hasOwnProperty('iso'))  this.country = this.countries.find( country => country.iso === 'RU' );
    this.country = this.countries.find( country => country.iso === 'RU' );

	},
};
</script>

<style>
#callback-modal .close {
  width: 40px;
  height: 26px;
}

#callback-modal {
  /* overflow: hidden; */
}

body.modal-open {
  overflow-y: hidden !important;
}

#callback-modal .card-body {
  /* padding: 0px 32px 0px 32px; */
  padding: 0px 0px 30px 0px;
}

#callback-modal .close {
  color: rgba(169, 169, 169, 1);
  background: rgba(169, 169, 169, 0.25);
  /* transform: translate(-30px, -5px) !important; */
  transform: translate(-33px, -2px) !important;
  font-size: 22px;
}

#callback-modal .modal-title {
  font-weight: 600;
}

.p-f {
  padding: 0.438rem 1rem !important;
}

#callback-modal input,
#callback-modal input:focus {
  border-radius: 100px;
  background-color: #f4f5f6;
  border-color: #f4f5f6;
}

#callback-modal .modal-body {
  padding: 8px 40px 0px 40px;
}

#callback-modal .modal-header {
  padding-left: 40px;
}

#callback-modal label {
  font-size: 16px;
  font-weight: 500;
  /* margin-bottom: 1.2857rem; */
  margin-bottom: 0.9rem;
  padding-top: 0.3rem;
}

#callback-modal .img-fluid {
  width: 35px;
  height: 35px;
  /* margin-right: 16px; */
}

#callback-modal .btn-block {
  border-radius: 100px;
  /* background-color: #426FF6 !important; */
  background-color: rgba(26, 86, 255, 1);
  color: #fff;
}

#callback-modal .input-group {
  border-radius: 100px;
}

.mesanger-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.btn-callback {
  margin-top: 35px;
}

.l-border0 {
  border-radius: 0px 100px 100px 0px !important; 
}

.l-border100 {
  border-radius: 100px; 
}

.cb-flags .input-group-text {
  border-radius: 100px 0px 0px 100px !important; 
  background-color: rgb(244, 245, 246) !important;
  border-color: rgb(244, 245, 246) !important;
}

.cb-flags .input-group:not(.bootstrap-touchspin):focus-within .input-group-text, .cb-flags .input-group-text:focus, .cb-flags .input-group-text a:focus, .cb-flags .input-group-text span:focus  {
  background-color: rgb(244, 245, 246) !important;
  border-color: rgb(244, 245, 246) !important;
}
</style>
